import React, { useState, useEffect, useRef } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { config } from '../../config';
import { analytics } from './firebase';  // Import analytics
import { logEvent } from 'firebase/analytics';

const GoogleAuth = ({ onLoginSuccess, onLoginFailure, id }, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(null);

    // Load user session from HTTP cookie (JWT token) and validate it on page load
    useEffect(() => {
        const loadUserSession = async () => {
            console.log("Component mounted, loading user session...");
            const jwtToken = getCookie('auth_token');
            console.log("JWT Token in loadUserSession:", jwtToken); 
            if (jwtToken) {
                try {
                    const response = await fetch(`${config.baseURL}/validate_token.php`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${jwtToken}`,
                        },
                        credentials: 'include',
                    });

                    const data = await response.json();

                    // If user data is returned, update the state and call onLoginSuccess
                    if (data.user) {
                        setUser(data.user);
                        onLoginSuccess(data.user);  // Notify parent component that login was successful
                    } else {
                        removeCookie('auth_token');
                        setUser(null);  // Clear user data if token is invalid
                    }
                } catch (error) {
                    removeCookie('auth_token');
                    setUser(null);  // Clear user data if validation fails
                }
            }
        };

        loadUserSession();
    }, []);  // Empty dependency array makes this effect run only once on mount

    // Google login handler
    const login = useGoogleLogin({
        onSuccess: (response) => {
            setIsLoading(false);
            handleLoginSuccess(response);
        },
        onError: (error) => {
            setIsLoading(false);
            handleLoginFailure(error);
        },
        scope: 'openid profile email',
        responseType: 'token',
    });

    const handleButtonClick = () => {
        if (isLoading) return;
        setIsLoading(true);
        login();
    };

    const handleLoginSuccess = async (response) => {
        if (response?.access_token) {
            await sendGoogleTokenToBackend(response.access_token);
        } else {
            toast.error('Google login failed. Please try again.');
        }
    };

    const handleLoginFailure = (error) => {
        toast.error('Login failed. Please try again.');
    };

    const sendGoogleTokenToBackend = async (googleToken) => {
        try {
            const response = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
                headers: {
                    Authorization: `Bearer ${googleToken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();

                const { email, name, id, picture, verified_email } = data;
                const guestId = localStorage.getItem('userId');
                // Send user data to your backend
                const backendResponse = await fetch(`${config.baseURL}/login.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email,
                        name,
                        social_id: id,
                        profile_picture: picture,
                        is_email_verified: verified_email,
                        Guest_Id: guestId ,
                    }),
                });

                const backendData = await backendResponse.json();

                if (backendData.jwt) {
                    // Save JWT to cookies
                    setCookie('auth_token', backendData.jwt, 7);
                    setUser({
                        email,
                        name,
                        picture,
                        id: backendData.user_id,
                    });

                    onLoginSuccess({
                        email,
                        name,
                        picture,
                        id: backendData.user_id,
                    });

                    logEvent(analytics, 'login', { method: 'Google', email });
                } else {
                    toast.error('Authentication failed. Please try again.');
                }
            } else {
                toast.error('Failed to fetch user profile from Google.');
            }
        } catch (error) {
            toast.error('Failed to fetch user profile from Google.');
        }
    };

    const handleLogout = async () => {
        try {
            removeCookie('auth_token');
            await fetch(`${config.baseURL}/logout.php`, { method: 'POST', credentials: 'include' });
            logEvent(analytics, 'logout', { method: 'Google' });
            setUser(null);
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();  // Refresh the page after logout
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    // Utility functions to handle cookies
    const setCookie = (name, value, days) => {
        const d = new Date();
        d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = `${name}=${value};${expires};path=/`;
    };

    const getCookie = (name) => {
        const nameEq = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i].trim();
            if (c.indexOf(nameEq) === 0) return c.substring(nameEq.length, c.length);
        }
        return "";
    };

    const removeCookie = (name) => {
        setCookie(name, "", -1); // Set expiration date to the past
    };

    React.useImperativeHandle(ref, () => ({
        login: handleButtonClick,
    }));

    return (
        <>
            {user ? (
                <div>
                    <button onClick={handleLogout} id={id}>Logout</button>
                </div>
            ) : (
                <button onClick={handleButtonClick} id={id} disabled={isLoading}>
                    {isLoading ? 'Login' : 'Login'}
                </button>
            )}
        </>
    );
};

export default React.forwardRef(GoogleAuth);
