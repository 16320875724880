import React from 'react';
import { Routes, Route } from 'react-router-dom';  
import Main from './library/Main'; 
import LandingPage from './library/components/landingpage'; 
import PrivacyPolicy from '././library/components/PrivacyPolicy';  
import TermsAndCondition from '././library/components/TermsAndCondition';  
import Refund from '././library/components/Refundpolicy';
import Blogs from './library/components/Blogs';
import Blogpage from './library/components/Blogpage';
function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />  
      <Route path="/Dashboard" element={<Main />} />  
      <Route path="/privacy" element={<PrivacyPolicy />} />  
      <Route path="/terms&condition" element={<TermsAndCondition />} />  
      <Route path="/refundpolicy" element={<Refund />} />  
      <Route path="/blog" element={<Blogs />} />
      <Route path="/blog/:title" element={<Blogpage />} />
      <Route path="*" element={<LandingPage />} />
    </Routes>
  );
}

export default App; 





