import React from 'react';
import writeLogo from '../../assets/write logo.svg'; 
import writeLogoActive from '../../assets/write logo - Copy.svg';
import replyLogo from '../../assets/reply logo.svg'; 
import replyLogoActive from '../../assets/reply logo - Copy.svg';
import historyLogo from '../../assets/history_logo.svg'; 
import historyLogoActive from '../../assets/history_logo - Copy.svg'; 
import './TabButtons.css';

import {config} from '../../config';

const TabButtons = ({ activeTab, onTabChange }) => {
    const buttonColor = config.color;

    const handleTabChange = (tab) => {
        onTabChange(tab);
        
       
    };

    return (
        <div className='tab-container'>
            <div className="tabs_btn">
                <button
                    className={`tabs-btn ${activeTab === 'write' ? 'active' : ''}`}
                    onClick={() => handleTabChange('write')}
                    style={{
                        color: activeTab === 'write' ? 'White' : '#9AA4B8',
                       
                    }}
                >
                   {/*  <img 
                        src={activeTab === 'write' ? writeLogoActive : writeLogo} 
                        alt="Write" 
                        className="tabs-icon" 
                    /> */}
                    {config.Writetab}
                </button>
                <button
                    className={`tabs-btn ${activeTab === 'history' ? 'active' : ''}`}
                    onClick={() => handleTabChange('history')}
                    style={{
                        color: activeTab === 'history' ? 'White' : '#9AA4B8',
                        
                    }}
                >
                    {/* <img 
                        src={activeTab === 'history' ? historyLogoActive : historyLogo} 
                        alt="History" 
                        className="tabs-icon" 
                    /> */}
                    History
                </button>
            </div>
        </div>
    );
};

export default TabButtons;
