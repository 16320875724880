import React, { useState, useEffect } from 'react';
import './Main.css';
import History from './components/History';
import FAQ from './components/FAQ';
import Buypremium from './components/Buypremium';  
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndCondition from './components/TermsAndCondition';
import Refundpolicy from './components/Refundpolicy';
import GoogleButton from './components/GoogleAuth';
import Generatepage from "./components/Generatepage";
import HistoryPreview from "./components/HistoryPreview";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from './components/Popup';
import ReactGA from 'react-ga4';
import { analytics } from './components/firebase';
import { logEvent } from 'firebase/analytics';  
import { app } from './components/firebase';
import { auth } from './components/firebase';
import { config } from '../config'; 
import { useRef } from 'react';
import Tabbuttons from './components/TabButtons'
import { getAuth, signInAnonymously } from 'firebase/auth';
import imageSrc from "../assets/upgradetopro.png";
import { BrowserRouter as Router, Route, Switch , useLocation,useNavigate  } from 'react-router-dom';
function App() {
    const [activeTab, setActiveTab] = useState('write');    
    const [isOpen, setIsOpen] = useState(false);
    const [showPremium, setShowPremium] = useState(false);
    const [showHistoryPreview, setShowHistoryPreview] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [result, setResult] = useState('');
    const [showGenerationPage, setShowGenerationPage] = useState(false);
    const [Topic, setTopic] = useState('');
    const [Input1, setInput1] = useState('');
    const [Input2, setInput2] = useState('');
    const [Input3, setInput3] = useState('');
    const [Input4, setInput4] = useState('');
    const [Input5, setInput5] = useState('');
    const [keyPoints, setKeyPoints] = useState('');
    const [SupportiveInput, setSupportiveInput] = useState('');
    const [language_create_title, setLanguage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({}); // Error state for validation
    const subscriptionStatus = JSON.parse(localStorage.getItem('subscriptionStatus'));
    const [popupMessage, setPopupMessage] = useState('');
    const [isPopupVisible, setPopupVisible] = useState(false);
    const googleanalytics  = process.env.REACT_APP_GOOGLE_ANALYITCS_ID;
    const [activeButton, setActiveButton] = useState('home');
    const [activeSliderButton, setActiveSliderButton] = useState('home');
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const [musicList, setMusicList] = useState([]);
    const [selectedSong, setSelectedSong] = useState('');
    const audioRef = useRef(null);
    const [category, setCategory] = useState('');
    const premiumButtonRef = useRef(null);
    const [generatedResult, setGeneratedResult] = useState('');
    const auth = getAuth(app);
    const [playingSong, setPlayingSong] = useState(null); // Track the currently playing song
    const [isPlaying, setIsPlaying] = useState(false); 
    const navigate = useNavigate();
    const location = useLocation();
    const googleAuthRef = useRef(null);
    const [prompt, setprompt] = useState('');
    const [selectduration, setselectduration] = useState('30');
    const [selectfontcolor, setselectfontcolor] = useState('White');
    const [selectTextStyle, setSelectTextStyle] = useState("Default");
  const [selectStyleColor, setSelectStyleColor] = useState("Black"); // to store the selected color
    const [selectaudio, setselectaudio] = useState('Formal');
    const [selectsize, setselectsize] = useState('16:9');
    const [clickCount, setClickCount] = useState(0);
    const [isPro, setIsPro] = useState(false);
    React.useEffect(() => {
       
        document.documentElement.style.setProperty('--main-color', config.color);
    }, [config.color]);
    
    useEffect(() => {
        ReactGA.initialize(googleanalytics); // Replace with your tracking ID
        ReactGA.send('pageview'); // Track initial page view
    }, []);
    useEffect(() => {
        ReactGA.initialize(googleanalytics); 
        ReactGA.send('pageview'); 
        
      
        ReactGA.event({
          category: 'Faceless_video', 
          action: 'pageview_FacelessVideo', 
          label: 'Page View for Faceless Video Generator', 
        });
      }, []);
    useEffect(() => {
        if (analytics) {
          logEvent(analytics, 'Dashborad_opend_FacelessVideo');
        } else {
         
        }
      }, []);

      useEffect(() => {
        const savedState = localStorage.getItem('showComponent');
        if (savedState === 'true') {
            setShowPremium(true);
            localStorage.removeItem('showComponent');
        }
      }, []);

      


      async function signInUserAnonymously() {
        try {
          // Try to sign in the user anonymously
          const userCredential = await signInAnonymously(auth);
          const user = userCredential.user;          
          // Save the user ID to localStorage
          localStorage.setItem('userId', user.uid);
        } catch (error) {
          
        }
      }

      const getCookie = (name) => {
        const nameEq = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i].trim();
            if (c.indexOf(nameEq) === 0) return c.substring(nameEq.length, c.length);
        }
        return "";
    };

      


      const handleRedirect = async () => {
        // Step 1: Extract subscription details from the URL (query parameters)
        const urlParams = new URLSearchParams(window.location.search);
        const subscriptionId = urlParams.get('subscription_id');
        const status = urlParams.get('status');
    
        // If subscription_id or status are not found in the URL, handle accordingly
        if (!subscriptionId || !status) {
            console.error('Missing subscription details in URL.');
            return;
        }
    
        // Step 2: Send the subscription details to the backend
        try {
            const jwtToken = getCookie('auth_token'); // Get JWT token from cookies (you should implement `getCookie`)
    
            if (!jwtToken) {
                console.error('User is not authenticated. No JWT token found.');
                return;
            }
    
            // Send subscription data to backend
            const response = await fetch(`${config.baseURL}/save_dodo_subscription.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    subscription_id: subscriptionId,
                    status: status,
                }),
            });
    
            const result = await response.json();
    
            // Step 3: Handle response from the backend
            if (response.status === 200 && result.success) {
                
    
                // Step 4: Store subscription status and plan ID in localStorage
                localStorage.setItem('subscriptionStatus', JSON.stringify(result.subscription_status.toUpperCase()));

                localStorage.setItem('planId', JSON.stringify(result.plan_id)); // Save plan_id to localStorage
                // Optionally, show a success message to the user
                toast('You have successfully subscribed to the plan! Enjoy your benefits!', {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
              });
  
              // Step 5: Clear URL parameters
              const currentUrl = window.location.href;
              const newUrl = currentUrl.split('?')[0]; // Get the base URL without query params
              window.history.replaceState({}, '', newUrl); 
            } else {
                console.error('Error saving subscription:', result);
                
            }
        } catch (error) {
            console.error('Error during subscription save:', error);
          
        }
    };
    
    // Call this function when the component mounts or when the URL changes
    useEffect(() => {
        handleRedirect();
    }, []);
      // Call the function to sign in anonymously on page load
      useEffect(() => {
        // Check if the user is already signed in
        const existingUserId = localStorage.getItem('userId');
        if (!existingUserId) {
          signInUserAnonymously();
        }
      }, []);
      const handleGeneratedResult = (result) => {
        setGeneratedResult(result);
        setIsLoading(false);
        setShowPrivacyPolicy(false); 
        setShowGenerationPage(true);
        setShowPremium(false);
        setShowHistoryPreview(false);
        setIsButtonVisible(false);  // Update the state with the result from CreateTitle
      };

      const BuypremiumError = () => {
        setShowPrivacyPolicy(false); 
        setShowGenerationPage(false);
        setShowPremium(false);
        setShowHistoryPreview(false);
        setIsButtonVisible(false);
        setShowPremium(true); 
        setPopupMessage('Your Daily Limit Reached. Buy Premium to Continue.');
        setShowPremium(true);
        setIsButtonVisible(false);
        setPopupVisible(true);
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 0); // Update the state with the result from CreateTitle
      };
  

   
    const toggleOverlay = () => setIsOpen((prev) => !prev);
   
       
   

    useEffect(() => {        
            checkSubscription();  
        
    }, []);  // Empty dependency array ensures this runs only once on mount
    
    const checkSubscription = async () => {
        try {
            const response = await fetch(`${config.baseURL}/check_subscription.php`, {
                method: 'GET',
                credentials: 'include',
            });
    
            const data = await response.json(); // Parse the response as JSON
    
            if (data.error) {
                
            } else {
                // Handle the subscription data here
                  localStorage.setItem('subscriptionStatus', JSON.stringify(data.subscription_status));
                  localStorage.setItem('planId', JSON.stringify(data.plan_id)); // Save plan_id to localStorage
            }
        } catch (error) {
           
        }
    };

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const handleHomeClick = () => {
      navigate('/Dashboard');
        setShowPremium(false);
        setIsButtonVisible(true);
        setShowPrivacyPolicy(false);
        setShowGenerationPage(false);
        setShowHistoryPreview(false);
        setTopic('');
        setInput1('');
        setInput2('');
        setInput3('');
        setInput4('');
        setInput5('');
        setKeyPoints('');
        setSupportiveInput('');
        setLanguage('');
        setActiveTab('write'); 
        logEvent(analytics, 'Home_btn_FacelessVideo', {
            button_name: 'home_button',
            page: '_FacelessVideo_home', 
        });// Show main page content
    };


    const handleBuyPremiumClick = () => {
        setShowPremium(true);
        setIsButtonVisible(false);
        setShowPrivacyPolicy(false);
        setShowGenerationPage(false);
        setShowHistoryPreview(false);
        setTopic('');
        setKeyPoints('');
        setSupportiveInput('');
        setLanguage('');
        setInput1('');
        setInput2('');
        setInput3('');
        setInput4('');
        setInput5('');
        logEvent(analytics, '3_days_btn__FacelessVideo', {
            button_name: '3 Days Free trial',
            page: '_FacelessVideo_home',
            action: 'Opens Premium page',
        });
    };
  
    const handleTermsClick = () => {
      navigate('/terms&condition');
        setShowPremium(false);
        setIsButtonVisible(false);
        setShowPrivacyPolicy(true);
        setShowGenerationPage(false);
        setShowHistoryPreview(false);
        setTopic('');
        setKeyPoints('');
        setSupportiveInput('');
        setLanguage('');
        setInput1('');
        setInput2('');
        setInput3('');
        setInput4('');
        setInput5('');
        logEvent(analytics, 'terms&condition_btn_FacelessVideo', {
            button_name: 'terms&condition_page',
            page: '__FacelessVideo_home',
            action: 'Opens Privacy page',
        }); 
    };

    const handleBlogClick = () => {
      navigate('/blog');
        setShowPremium(false);
        setIsButtonVisible(false);
        setShowPrivacyPolicy(true);
        setShowGenerationPage(false);
        setShowHistoryPreview(false);
        setTopic('');
        setKeyPoints('');
        setSupportiveInput('');
        setLanguage('');
        setInput1('');
        setInput2('');
        setInput3('');
        setInput4('');
        setInput5('');
        logEvent(analytics, 'terms&condition_btn_FacelessVideo', {
            button_name: 'terms&condition_page',
            page: '__FacelessVideo_home',
            action: 'Opens Privacy page',
        }); 
    };


    const handleRefundPolicyClick = () => {
      navigate('/refundpolicy');
        setShowPremium(false);
        setIsButtonVisible(false);
        setShowPrivacyPolicy(true);
        setShowGenerationPage(false);
        setShowHistoryPreview(false);
        setTopic('');
        setKeyPoints('');
        setSupportiveInput('');
        setLanguage('');
        setInput1('');
        setInput2('');
        setInput3('');
        setInput4('');
        setInput5('');
        logEvent(analytics, 'Refund_btn_FacelessVideo', {
            button_name: 'Refund_page',
            page: '__FacelessVideo_home',
            action: 'Opens Privacy page',
        });
    };
    const handlePrivacyPolicyClick = () => {
      navigate('/privacy');
        setShowPremium(false);
        setIsButtonVisible(false);
        setShowPrivacyPolicy(true);
        setShowGenerationPage(false);
        setShowHistoryPreview(false);
        setTopic('');
        setKeyPoints('');
        setSupportiveInput('');
        setLanguage('');
        setInput1('');
        setInput2('');
        setInput3('');
        setInput4('');
        setInput5('');
        logEvent(analytics, 'Privacy_btn_FacelessVideo', {
            button_name: 'Privacy_page',
            page: '__FacelessVideo_home',
            action: 'Opens Privacy page',
        });
    };
    
    const handleSliderButtonClick = (callback) => {
        toggleOverlay();
         // Close slider
        callback(); 
        logEvent(analytics, 'Slider_opend_FacelessVideo', {
            button_name: 'Slider_opend_FacelessVideo',
        });// Call the passed callback function
    };

    const handleBackToMain = () => {
      navigate('/Dashboard');// Resetting form fields to default values
        setprompt(""); // Reset the prompt textarea
        setselectfontcolor("#FFFFFF"); // Reset font color to default (White)
        setSelectTextStyle("Default"); // Reset text style to default
        setSelectStyleColor("#FFFFFF"); // Reset style color to default (White)
        setselectduration("30"); // Reset duration to 30 seconds
        setSelectedSong(""); // Reset selected song (if any)
        setselectsize("16:9"); // Reset aspect ratio to default
        setDropdownOpen(false); // Close the music dropdown
        setPlaying(false); // Reset play/pause status
        setErrors({}); // Reset form errors
        setActiveTab("write"); // Optionally reset the active tab
        setShowPrivacyPolicy(false); 
        setShowGenerationPage(false);
        setShowPremium(false);
        setShowHistoryPreview(false);
        setIsButtonVisible(true);// Hide the privacy policy
    };

    const handleBackToHistory = () => {
      // Navigates to the previous page in history
      navigate('/Dashboard');  // Takes the user back to the previous page
    
      // Optionally reset the form fields to their default values
      setprompt(""); // Reset the prompt textarea
      setselectfontcolor("#FFFFFF"); // Reset font color to default (White)
      setSelectTextStyle("Default"); // Reset text style to default
      setSelectStyleColor("#FFFFFF"); // Reset style color to default (White)
      setselectduration("30"); // Reset duration to 30 seconds
      setSelectedSong(""); // Reset selected song (if any)
      setselectsize("16:9"); // Reset aspect ratio to default
      setDropdownOpen(false); // Close the music dropdown
      setPlaying(false); // Reset play/pause status
      setErrors({}); // Reset form errors
      setActiveTab("history"); // Optionally reset the active tab
      setShowPrivacyPolicy(false); 
      setShowGenerationPage(false);
      setShowPremium(false);
      setShowHistoryPreview(false);
      setIsButtonVisible(true); // Hide the privacy policy
    };

    const handleLoginSuccess = (userProfile) => {
        if (userProfile) {
          
           
                logEvent(analytics, 'Login_btn_clicked__FacelessVideo', {
                    button_name: 'login_button_FacelessVideo',
                });
                
                
                
        } else {
           
            
        }
        
    };

    const handleLoginFailure = (error) => {
      
        if (error.error) {
            alert(`Login failed: ${error.error}`);
        } else {
            
        }
    };

    const handleLogout = () => {
      localStorage.removeItem('userProfile');
        localStorage.removeItem('user');
        localStorage.removeItem('subscriptionStatus');
        sessionStorage.removeItem('userProfile'); 
        sessionStorage.removeItem('subscriptionStatus'); 
        localStorage.removeItem('rzp_checkout_anon_ids');
        localStorage.removeItem('rzp_checkout_anon_id');
        localStorage.removeItem('rzp_device_id');
        localStorage.removeItem('subscribedPlan');
        localStorage.removeItem('selectedPlan');
        localStorage.removeItem('planId');
        
        window.location.reload();
        logEvent(analytics, 'Logout_btn_FacelessVideo', {
            button_name: 'logout_button',
        });
        
        toast('Logout successful!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover:false ,
            draggable: true,
            progress: undefined,
            theme: "light",
            
            });
    };

    const handleDocumentSelect = (generatedResult) => {
        setResult(generatedResult);
       
        setShowHistoryPreview(true);
    };
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);

    
        
    const closePopup = () => {
        setPopupVisible(false);
      };
         

      const useLocalStorageClickCount = () => {
        useEffect(() => {
          const storedCount = JSON.parse(localStorage.getItem('clickCount')) || {};
          let currentClickCount = storedCount.count || 0;
         console.log(storedCount);
         console.log(storedCount.count);
          const lastLocalDate = new Date(storedCount.lastClickDate);
          console.log(lastLocalDate);
          const now = new Date();
          if (now - lastLocalDate > 24 * 60 * 60 * 1000) {
            // Reset the count if more than 24 hours have passed
            currentClickCount = 0;
            localStorage.setItem('clickCount', JSON.stringify({ count: 0, lastClickDate: now.toISOString() }));
          }
    
          setClickCount(currentClickCount);
        }, []);
      };
    
      useLocalStorageClickCount(); // Hook to update click count from localStorage
    
      // Function to get the user's click count and status from the backend
      const getClickCount = async () => {
        try {
          const response = await fetch(`${config.baseURL}/click_count.php`, {
            method: 'GET',
            credentials: 'include', // Include cookies if needed for session handling (including JWT)
          });
    
          const data = await response.json();
    
          if (data.error) {
            if (data.error === 'User not found' || data.error === 'Token not provided or expired') {
              
              return { error: data.error };
            }
           
            return { error: 'Unexpected error' };
          }
    
          return { click_count: data.click_count, is_pro: data.is_pro === 'yes' };
        } catch (error) {
        
          return { error: 'API call failed' };
        }
      };
    
    const handleGenerate = async () => {
    setErrors({});  // Reset errors
    let hasError = false;

    logEvent(analytics, 'User Generated FacelessVideo', {
        user_action: 'Clicked on Generate Video'
    });

    // Validation checks
    if (!prompt) {
        setErrors(prev => ({ ...prev, prompt: 'Prompt is required.' }));
        hasError = true;
        setTimeout(() => {
            setErrors(prevErrors => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors.prompt;
                return updatedErrors;
            });
        }, 3000);
    }

    if (hasError) return;

    // Show Generation Page immediately
    setShowGenerationPage(true); // Display generation page immediately
    setIsButtonVisible(false);
    setShowPrivacyPolicy(false);
    setShowPremium(false);
    setShowHistoryPreview(false);
    document.body.classList.add('no-scroll' , 'no-interaction');  // Disable scroll

    // Set loading state to true (loader will show)
    setIsLoading(true);

    // Make the backend call now that the page is already rendered
    try {
        // Get click count and pro status from the backend
        const clickCountResponse = await getClickCount();

        if (clickCountResponse.error) {
            // Handle localStorage click count if API failed
            const storedClickData = JSON.parse(localStorage.getItem('clickCount')) || { count: 0, lastClickDate: new Date().toISOString() };

            if (storedClickData.count >= 5) {
                BuypremiumError(); // Show the premium page if click count exceeds limit
                return;
            } else {
                storedClickData.count += 1;
                localStorage.setItem('clickCount', JSON.stringify(storedClickData)); // Increment click count in localStorage
            }
        } else {
            // Backend response for pro user or regular user
            if (clickCountResponse.is_pro) {
                if (clickCountResponse.click_count < 25) {
                    setIsPro(true);
                } else {
                    BuypremiumError(); // Show premium page if the pro user exceeds limit
                    return;
                }
            } else {
                if (clickCountResponse.click_count < 5) {
                    // Regular user can proceed if click count is less than 5
                } else {
                    BuypremiumError(); // Show premium page if regular user exceeds limit
                    return;
                }
            }
        }

        const userId = localStorage.getItem('userId');

        // Prepare the request body with form data
        const requestBody = {
            Prompt: prompt,
            Duration: selectduration,
            size: selectsize,
            Guest_Id: userId,
            Music: currentSong ? currentSong.file : "No Music", 
            Font: boxData[selectedBoxIndex].font,
            FontColor:selectfontcolor,
            TextStyle:selectTextStyle,
            StyleColor: selectStyleColor,
        };

        // Send a request to the backend for content generation
        const generationResponse = await fetch(`${config.baseURL}/ai_generate.php`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
        });

        const data = await generationResponse.json();

        if (data.status === 'success') {
            // Handle the video_url and story from the response
            const videoUrl = data.video_url;
            const storyText = data.story;

            // Now that both video blob and story text are ready, pass them to the handler function
            handleGeneratedResult({
                result: videoUrl  // Pass the video blob to the handler
            });

            // Log the event for analytics purposes
            logEvent(analytics, 'Faceless Video Generated ', {
                component_name: 'CreateContent',
                videoUrl: videoUrl // Log the video URL as well
            });

        } else {
            setPopupMessage('Something went wrong! Please try again.');
            handleBackToMain();
            logEvent(analytics, 'User Faced Backend Generation Error on FacelessVideo', {
                user_action: 'Clicked Generated Video'
            });
            setPopupVisible(true);
        }

    } catch (error) {
      handleBackToMain();
        alert("An error occurred while generating the document.");
        handleBackToMain();
    } finally {
        setIsLoading(false);
        document.body.classList.remove('no-scroll', 'no-interaction');  // Enable scroll
    }
};

    
    


      const [songs, setSongs] = useState([]);
      const [currentSong, setCurrentSong] = useState(null);
      const [playing, setPlaying] = useState(false);
      const [dropdownOpen, setDropdownOpen] = useState(false); // To control dropdown open/close state
      const audioPlayer = useRef(null);
      const dropdownRef = useRef(null);
    
      useEffect(() => {
        const songList = [

          { name: 'Synthwave goose-blade runner 2049', file: 'Synthwavegoose-bladerunner2049.mp3' },
          { name: 'Not Responsible (Slowed)', file: 'NotResponsible(Slowed).mp3' },
          { name: 'FrankJavCee - Simpsonwave 1995', file: 'FrankJavCee-Simpsonwave1995.mp3' },
          { name: 'INTERWORLD - METAMORPHOSIS', file: 'INTERWORLD-METAMORPHOSIS.mp3' },
          { name: 'WINDOWS 95', file: 'WINDOWS95.mp3' }
        ];
        setSongs(songList);
      }, []);
    
      // Play or Pause the song
      const togglePlayPause = (song, event) => {
        event.stopPropagation(); // Prevent the click from propagating and closing the dropdown
    
        if (currentSong && currentSong.file !== song.file) {
          stopMusic(); // Stop the current song if a new one is selected
        }
    
        if (audioPlayer.current.paused) {
          audioPlayer.current.src = `/Music/${song.file}`; // Music path from public/music folder
          audioPlayer.current.play();
          setCurrentSong(song);
          setPlaying(true);
          logEvent(analytics, 'Music played on faceless video app ', {
            component_name: 'CreateContent',
            videoUrl: song.file// Log the video URL as well
          });
        } else {
          audioPlayer.current.pause();
          setPlaying(false);
        }
      };
    
      // Stop current music
      const stopMusic = () => {
        if (audioPlayer.current) {
          audioPlayer.current.pause();
          setPlaying(false);
          setCurrentSong(null);
        }
      };
      const stopselectedSong=() =>{
        if (audioPlayer.current) {
          audioPlayer.current.pause();
          setPlaying(false);
        }

      }
    
      // Handle outside click to close the dropdown
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          const handleSongClick = (song) => {
            setCurrentSong(song); // Set the selected song as the current song
            setDropdownOpen(false);
            stopselectedSong(); // Close the dropdown when a song is selected
          };
          
          // Close dropdown if clicked outside
        }
      };
    
      // Handle song selection and close dropdown
      const handleSongClick = (song) => {
        setCurrentSong(song); // Set the selected song as the current song
        setDropdownOpen(false);
        stopselectedSong(); // Close the dropdown when a song is selected
      };
    
      // Handle 'No Music' selection
      const handleNoMusicClick = () => {
        setCurrentSong(null); // Set currentSong to null to indicate no music is selected
        setDropdownOpen(false);
        stopMusic();  // Close the dropdown
      };
    
      useEffect(() => {
        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);
    
      // Form submission handler
      const handleSubmit = (event) => {
        event.preventDefault();
        // Log the selected song or 'No Music' if no song is selected
        console.log("Selected Song:", currentSong ? currentSong.name : "No Music");
        // You can now use the selected song value as part of the form submission logic
      };

      const handlePrivacyButtonClick = () => {
        // Navigate to the PrivacyPolicy route when the button is clicked
        navigate('privacy');
      };




      const [loadingState, setLoadingState] = useState('generating'); // Initial state is 'generating'

useEffect(() => {
  // Show 'Generating Video' first
  setLoadingState('generating');

  // Change to 'Loading' after 2 seconds
  setTimeout(() => {
    setLoadingState('loading');
  }, 2000);

  // Change to 'Please wait...' after 4 seconds
  setTimeout(() => {
    setLoadingState('waiting');
  }, 4000);

}, []); // UseEffect runs once on component mount




const [selectedBoxIndex, setSelectedBoxIndex] = useState(0); // Default selected box is the first one
  
  const boxData = [
    
    { text: "DM Serif Text", font: "DM Serif Text" }, 
    { text: "No Caption", font: "No Caption" },   
    { text: "Bangers", font: "Bangers" },
    { text: "Oswald", font: "Oswald" },
    { text: "Montserrat", font: "Montserrat" },
    { text: "Palanquin Dark", font: "Palanquin Dark" },
    { text: "Kanit", font: "Kanit" }
  ];

  const handleBoxClick = (index) => {
    setSelectedBoxIndex(index); // Update the selected box when clicked
    console.log(`Selected Box Text: ${boxData[index].text}`); // Log the selected box text
  };
   

  const colorOptions = [
    "Black", "White", "Red", "Green", "Blue", "Yellow", 
    "Cyan", "Magenta", "Gray", "Orange"
  ];


    return (

        <div className="App">
            <ToastContainer />
            <div className='header_mobile'>
                <img
                    src={config.hamburger}
                    alt="Menu"
                    className="hamburger"
                    onClick={toggleOverlay}
                />
                <div className='login_crown_container'>
                    <img src={config.crown} alt="Crown" onClick={handleBuyPremiumClick} />
                
                    
            <GoogleButton ref={googleAuthRef} id="logout_header" onLoginSuccess={handleLoginSuccess} onLoginFailure={handleLoginFailure} />
                                                      
                </div>
            </div>
            {(subscriptionStatus !== 'ACTIVE' || !subscriptionStatus) && isButtonVisible && (
  <div className="floating-button" onClick={handleBuyPremiumClick}>
    <img src={imageSrc} alt="Floating Button" />
  </div>
)}
            {isOpen && (
                <div className="overlay" onClick={toggleOverlay}>
                    <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
                        <div className='topslider'>
                            <div className="close-icon" onClick={toggleOverlay}>&#10005;</div>
                            <img src={config.frame} alt="Frame" className='frame' />
                          
                        </div>
                        <div className="button-container-slider">
                            <button className='buy-premium-slider' onClick={() => handleSliderButtonClick(handleBuyPremiumClick)}>
                                <img src={config.crown} alt="Crown" className='slidercrown' /> 3 Days Free Trial
                            </button>
                            <div className='buttonscrollonslider'>
                                <button className={`Home_btn_slider ${activeSliderButton === 'home' ? 'active' : ''}`} 
                                onClick={() => {
                                    handleSliderButtonClick(handleHomeClick);
                                    setActiveSliderButton('home'); // Set active state
                                }}>
                                    <span><img src={config.home} alt="Home" className='slider-Homeimg' /></span> Home
                                </button>
                                <button className={`Home_btn_slider ${activeSliderButton === 'Blog' ? 'active' : ''}`}
                                 onClick={() => {
                                    handleSliderButtonClick(handleBlogClick);
                                    setActiveSliderButton('Blog'); // Set active state
                                }}>
                                    <span><img src={config.blogimg} alt="Blog" className='slider-Homeimg' /></span> Blog
                                </button>
                                <button className={`Home_btn_slider ${activeSliderButton === 'privacy' ? 'active' : ''}`}
                                 onClick={() => {
                                    handleSliderButtonClick(handlePrivacyPolicyClick);
                                    setActiveSliderButton('privacy'); // Set active state
                                }}>
                                    <span><img src={config.privacy} alt="Privacy" className='slider-Homeimg' /></span> Privacy Policy
                                </button>
                                <button className={`Home_btn_slider ${activeSliderButton === 'Refund' ? 'active' : ''}`}
                                 onClick={() => {
                                    handleSliderButtonClick(handleRefundPolicyClick);
                                    setActiveSliderButton('Refund'); // Set active state
                                }}>
                                    <span><img src={config.refundimg} alt="Refund" className='slider-Homeimg' /></span> Refund Policy
                                </button>
                                <button className={`Home_btn_slider ${activeSliderButton === 'Terms' ? 'active' : ''}`}
                                 onClick={() => {
                                    handleSliderButtonClick(handleTermsClick);
                                    setActiveSliderButton('Terms'); // Set active state
                                }}>
                                    <span><img src={config.termsimg} alt="Terms" className='slider-Homeimg' /></span> Terms & Condition
                                </button>
                               
                            </div>
                           
                               
                            
                                <GoogleButton id="login_slider" onLoginSuccess={handleLoginSuccess} onLoginFailure={handleLoginFailure} />
                            
                        </div>
                    </div>
                </div>
            )}

            <div id='container'>
                <div className="left_panel">
                    <img src={config.logo} alt="Logo" className='logo_left_panel' />
                    <h1 className="left_title">{config.title}</h1>
                      {/*  <img src={config.buyP} alt="Buy Premium" className='buy-btn-img-left-panel' onClick={handleBuyPremiumClick} /> */}
                      <button className='buy-btn-img-left-panel' onClick={handleBuyPremiumClick} ><img src={config.crown} alt="Crown"  className='panelcrown' />3 Days Free Trial </button>

                     <div className='btn-scroll'> 
                        <button className={`scroll_btn ${activeButton === 'home' ? 'active' : ''}`} onClick={() => {
            handleHomeClick();
            setActiveButton('home');
        }}>
                        <span><img src={config.homelp} alt="Home" className='scroll-img' /></span> Home
                    </button>
                    <button className={`scroll_btn ${activeButton === 'privacy' ? 'active' : ''}`} onClick={() => {
            handlePrivacyPolicyClick();
            setActiveButton('privacy');
            /*  *//* handlePrivacyButtonClick(); */
        }}>
                        <span><img src={config.privacylp} alt="Privacy" className='scroll-img' /></span> Privacy Policy
                    </button>
                    <button className={`scroll_btn ${activeButton === 'Refund' ? 'active' : ''}`} onClick={() => {
            handleRefundPolicyClick();
            setActiveButton('Refund');
            
        }}>
                        <span><img src={config.refundimg} alt="Refund" className='scroll-img' /></span> Refund Policy
                    </button>
                    <button className={`scroll_btn ${activeButton === 'terms' ? 'active' : ''}`} onClick={() => {
            handleTermsClick();
            setActiveButton('terms');
            
        }}>
                        <span><img src={config.termsimg} alt="terms" className='scroll-img' /></span>Terms & Condition
                    </button>
                    <button className={`scroll_btn ${activeButton === 'blogs' ? 'active' : ''}`} onClick={() => {
            handleBlogClick();
            setActiveButton('blog');
            
        }}>
                        <span><img src={config.blogimg} alt="terms" className='scroll-img' /></span>Blog
                    </button>
                    </div>
                    
                   
                        <GoogleButton onLoginSuccess={handleLoginSuccess} id="login_left" onLoginFailure={handleLoginFailure} />
                    
                </div>

                <div className={`main_panel ${isLoading ? 'loading' : showGenerationPage ? 'generation' : showPremium ? 'premium' : showPrivacyPolicy ? 'privacy' : 'main'}`}>
  {isPopupVisible && <Popup message={popupMessage} onClose={closePopup} />}

  {isLoading ? (
    <div className="loader-container-main show-generating">
    <div className="loader-custom"></div>
    <div className="loading-text">Generating Video...</div>
  </div>
  )  : showGenerationPage ? (
    <Generatepage pathname="/Generatepage" result={generatedResult} setIsLoading={setIsLoading} onBack={handleBackToMain} />
  ) : showPremium ? (
    <Buypremium pathname="/Buypremium" onBack={() => {
      handleBackToMain();
      setActiveButton('home');
      setActiveSliderButton('home');
    }} />
  ) : showHistoryPreview ? (
    <HistoryPreview pathname="/HistoryPreview" result={result} onBack={handleBackToHistory} />
  ) : location.pathname === '/privacy' ? (
    <PrivacyPolicy pathname="/privacy" onBack={handleBackToMain} />
  ) : location.pathname === '/terms&condition' ? (
    <TermsAndCondition pathname="/terms&condition" onBack={handleBackToMain} />
  ) : location.pathname === '/refundpolicy' ? (
    <Refundpolicy pathname="/refundpolicy" onBack={handleBackToMain} />
  ) : (
    <div pathname="/home" className="main_page">
      <div className="title_main_page">
        <h1 className="title_name">{config.title} {subscriptionStatus === 'ACTIVE' && (
          <img src={config.proicon} alt="" className="proicon" />
        )}</h1>
      </div>
      
      <Tabbuttons activeTab={activeTab} onTabChange={setActiveTab} analytics={analytics} />
      
      <div className="form">
        {activeTab === 'write' && (
          <div>
            <div className="topic">Prompt/Story</div>
            <textarea
              className={`input_1 ${errors.prompt ? 'error' : ''}`}
              placeholder={config.ContentTopic}
              value={prompt}
              onChange={(e) => setprompt(e.target.value)}
              maxLength="1000"
            />
            {errors.prompt && <div className="error-message">{errors.prompt}</div>}

            <div className="topic">Select Font</div>
            <div id="app-container">
            
      <div id="scrollable-box-container">
        {boxData.map((item, index) => (
          <div
            key={index}
            id={`box-item-${index}`}
            className={`box-item ${selectedBoxIndex === index ? "box-selected" : ""}`}
            onClick={() => handleBoxClick(index)}
          >
            <span 
              id={`box-text-${index}`} 
              className="box-text" 
              style={{ fontFamily: item.font }}
            >
              {item.text}
            </span>
          </div>
        ))}
      </div>
    </div>
    {/* Conditionally render font color dropdown */}
    {selectedBoxIndex !== -1 && boxData[selectedBoxIndex].text !== "No Caption" && (
        <>
          <div className="topic">Select Font Color</div>
          <select
            className="input_3"
            name="tone"
            value={selectfontcolor}
            onChange={(e) => setselectfontcolor(e.target.value)}
          >
            <option value="#FFFFFF">White</option>
            <option value="#000000">Black</option>
            <option value="#FF0000">Red</option>
            <option value="#00FF00">Green</option>
            <option value="#0000FF">Blue</option>
            <option value="#FFFF00">Yellow</option>
            <option value="#00FFFF">Cyan</option>
            <option value="#FF00FF">Magenta</option>
            <option value="#808080">Gray</option>
            <option value="#FFA500">Orange</option>
          </select>
        </>
      )}

      {/* Conditionally render text style dropdown */}
      {selectedBoxIndex !== -1 && boxData[selectedBoxIndex].text !== "No Caption" && (
        <>
          <div className="topic">Select Text Style</div>
          <select
            className="input_3"
            name="tone"
            value={selectTextStyle}
            onChange={(e) => setSelectTextStyle(e.target.value)}
          >
            <option value="Default">Default</option>
            <option value="Text With Border">Text With Border</option>
            <option value="Text Inside Dark Box">Text Inside Dark Box</option>
            <option value="Text Inside Light Box">Text Inside Light Box</option>
          </select>

          {/* Conditionally render the style color dropdown */}
          {selectTextStyle !== "Default" && (
            <>
              <div className="topic">Select Style Color</div>
              <select
                className="input_3"
                name="color"
                value={selectStyleColor}
                onChange={(e) => setSelectStyleColor(e.target.value)}
              >
                {colorOptions.map((color) => (
                  <option key={color} value={color}>
                    {color}
                  </option>
                ))}
              </select>
            </>
          )}
        </>
      )}
            
            <div className="topic">Select Duration</div>
            <select
              className="input_3"
              name="tone"
              value={selectduration}
              onChange={(e) => setselectduration(e.target.value)}
            >
              <option value="30">30 seconds</option>
              <option value="45">45 seconds</option>
              <option value="60">60 seconds</option>
            </select>

            <div className="topic">Select Music</div>
            <div className="music-dropdown-container_backgroundmusic">
              <div ref={dropdownRef} className="dropdown_backgroundmusic">
                <button className="dropdown-btn_backgroundmusic" onClick={() => setDropdownOpen(!dropdownOpen)}>
                  {currentSong ? currentSong.name : 'No Music'}
                  <span className="dropdown-arrow">⌄</span>
                </button>
                {dropdownOpen && (
                  <div className="dropdown-content_backgroundmusic">
                    <div
                      className="dropdown-item_backgroundmusic"
                      onClick={handleNoMusicClick}
                    >
                      No Music
                    </div>
                    {songs.length === 0 ? (
                      <div>Loading...</div>
                    ) : (
                      songs.map((song) => (
                        <div
                          className="dropdown-item_backgroundmusic"
                          key={song.file}
                          onClick={() => handleSongClick(song)}
                        >
                          {song.name}
                          <div
                            className={`play-pause-btn_backgroundmusic ${playing && currentSong?.file === song.file ? 'pause' : 'play'}`}
                            onClick={(e) => togglePlayPause(song, e)}
                          >
                            {playing && currentSong?.file === song.file ? '❚❚' : '►'}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
              <audio ref={audioPlayer} />
            </div>

            <div className="topic">Select Aspect Ratio</div>
            <select
              className="input_3"
              name="tone"
              value={selectsize}
              onChange={(e) => setselectsize(e.target.value)}
            >
              <option value="16:9">16:9</option>
              <option value="9:16">9:16</option>
              <option value="1:1">1:1</option>
            </select>

            <div className="form-footer">
              <button
                className="buy-button_content"
                onClick={handleGenerate}
                disabled={isLoading}
              >
                Generate Video
              </button>
            </div>
          </div>
        )}

        {activeTab === 'history' && <History onDocumentSelect={handleDocumentSelect} />}
      </div>
                        
                        
                        
                        
                        
                        
                        
                        </div>
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    )}
                            







                </div>  
            
            
            
            
            
            </div>         
          
            {!showGenerationPage && <FAQ />}
        </div>
    
    );
}

export default App;