import React from 'react';
import './PrivacyPolicy.css'; 
import { config } from '../../config';
import { useNavigate } from 'react-router-dom';

const Refundpolicy = ({ onBack }) => {
  const navigate = useNavigate();
  
  const handleBackClick = () => {
    // Navigate back to the previous page or redirect to home
    navigate('/Dashboard'); // -1 takes the user back to the previous page in history
    // Or use navigate('/') to redirect to the home page or any default page
    // navigate('/'); // This would navigate to the home page
  };

  React.useEffect(() => {
    document.documentElement.style.setProperty('--main-color', config.color);
  }, [config.color]);

  return (
    <div className="privacy-policy-container">
      <div className="privacytopdiv">
        <button className="back-button" onClick={handleBackClick}>
          <img src={config.Backbutton} alt="Back" />
          <span>Refund & Cancellation Policy</span>
        </button>
      </div>

      <div id="policy-content">
        <div>{config.refundTitle}</div>
        <p>Last Updated: {config.refundEffectiveDate}</p>

        {config.refundPoints.map((section, index) => (
          <div key={index}>
            <div>{section.heading}</div>
            {section.subsections ? (
              section.subsections.map((sub, subIndex) => (
                <div key={subIndex}>
                  <div>{sub.heading}</div>
                  {sub.content.map((text, textIndex) => (
                    <p key={textIndex}>{text}</p>
                  ))}
                </div>
              ))
            ) : (
              <div>
                {section.content.map((text, textIndex) => (
                  <p key={textIndex}>{text}</p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Refundpolicy;
