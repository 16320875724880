import React, { useState, useEffect } from 'react';
import './RepetitionGroup.css'; // Import CSS
import copy from '../../assets/delete_video.svg';
import share from '../../assets/share_video.svg';
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";
import { config } from '../../config';
import ReactPlayer from 'react-player';

const History = ({ onDocumentSelect }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showNotificationCopy, setShowNotificationCopy] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  // States for delete popup
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deletingVideoId, setDeletingVideoId] = useState(null);
  const [deleteResult, setDeleteResult] = useState(null); // 'success' or 'error'
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    fetchHistoryData();
  }, []); // Check if user is logged in
  const userId = localStorage.getItem('userId');

  // Fetch history data from backend using the JWT token stored in cookies
  const requestBody = {
    Guest_Id: userId,
  };

  const fetchHistoryData = async () => {
    setLoading(true); // Set loading state before starting the fetch
    try {
      const response = await fetch(`${config.baseURL}/get_history.php`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      const result = await response.json();

      if (response.ok) {
        if (result.history && result.history.length > 0) {
          setHistoryData(result.history);
          setError(null); // Reset any previous error messages
        } else {
          if (result.success === false && result.message === 'Authorization token is required') {
            setError('Please log in to save and view your history');
            setIsLoggedIn(false); // Set the user as not logged in
          } else {
            setError('History not found');
            setHistoryData([]); // Ensure historyData is set to an empty array if history is empty
          }
        }
      } else {
        setError('An error occurred while fetching data.');
        setHistoryData([]); // Clear historyData if an error occurs
      }
    } catch (error) {
      setError('We couldn’t load your history. Please try again.');
      setHistoryData([]); // Ensure historyData is cleared in case of error
    } finally {
      setTimeout(() => {
        setLoading(false); // Stop loading after 5 seconds
      }, 5000);
    }
  };

  // Handle the delete button click
  const handleDeleteClick = (videoId) => {
    setDeletingVideoId(videoId); // Store the video ID to be deleted
    setShowDeletePopup(true); // Show the delete confirmation popup
  };

  const handleDeleteConfirmation = async () => {
    if (!deletingVideoId) return;

    setIsDeleting(true); // Set deleting state
    setDeleteResult(null); // Reset delete result before making the request

    try {
      // Send delete request to backend
      const response = await fetch(`${config.baseURL}/delete_video.php`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: localStorage.getItem('userId'),
          videoId: deletingVideoId,
        }),
      });

      const result = await response.json();

      if (result.success) {
        setDeleteResult('success');
        // Remove video from historyData if successful
        setHistoryData(prevData => prevData.filter(item => item.id !== deletingVideoId));
        setTimeout(() => {
          setShowDeletePopup(false);
          setDeleteResult('');
          fetchHistoryData();
        }, 1000);
      } else {
        setDeleteResult('error');
      }
    } catch (error) {
      
      setDeleteResult('error');
    } finally {
      setIsDeleting(false); // Hide loading state
    }
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false); // Hide the popup without doing anything
    setDeleteResult(null);
    fetchHistoryData(); // Reset the result
  };

  const handleCopy = (text) => {
    const textToCopy = text.trim(); // Clean up any unnecessary leading/trailing spaces
   // Debug log

    if (!textToCopy || textToCopy === '') {
     
      alert('No text available to copy.');
      return;
    }

    try {
      // Create a hidden textarea element to copy the text
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy; // Set the value of the textarea to the text you want to copy
      document.body.appendChild(textArea); // Append the textarea to the body

      // Select the content of the textarea
      textArea.select();
      textArea.setSelectionRange(0, 99999); // For mobile devices

      // Copy the selected content to clipboard
      document.execCommand('copy'); // This is the standard copy command in browsers

      // Remove the textarea after copying
      document.body.removeChild(textArea); // Clean up the DOM

     

      // Show a notification that the text has been copied
      setShowNotificationCopy(true);
      setTimeout(() => setShowNotificationCopy(false), 1000);

      // Log the action for analytics
      logEvent(analytics, 'text_copied_FacelessVideo', {
        text_length: textToCopy.length
      });
    } catch (err) {
      
      alert('There was an error copying the text. Please try again.');
    }
  };

  const handleShare = async (topic_type) => {
    try {
      const contentToShare = {
        title: `Create Stunning AI-Generated Videos with Ai Video Generator! 🚀`, // Promoting the AI-driven video creation
        text: `Experience the future of content creation! Watch this AI-generated video now and see how Ai Video Generator transforms your ideas into reality. 🌟 Don't miss out on the magic of AI! ${topic_type}`,
        url: topic_type, // Share the actual URL directly if needed
      };

      if (navigator.share) {
        // Sharing the content
        await navigator.share(contentToShare);
       
      } else {
        alert("Sharing is not supported in this browser.");
      }
    } catch (error) {
     
      alert('Sharing failed. Please try again.');
    }
  };

  const handleDocumentClick = (item) => {
    onDocumentSelect(item.topic_type);
    logEvent(analytics, 'document_clicked_FacelessVideo', {
      document_id: item.id,
      document_preview: item.ai_result.substring(0, 50) // Preview of the text
    });
  };
  useEffect(() => {
      if (showDeletePopup) {
        // Disable scrolling by setting body overflow to hidden
        document.body.style.overflow = 'hidden';
      } else {
        // Enable scrolling by resetting body overflow
        document.body.style.overflow = 'auto';
      }
    
      // Clean up when component is unmounted or popup is closed
      return () => {
        document.body.style.overflow = 'auto'; // Reset to default when unmounted
      };
    }, [showDeletePopup]); 

  // Loading, error, and empty state handling
  if (loading) {
    return (
      <div className="loader-history">
        <p className='loader-text '>Loading...</p>
      </div>
    );
  }

  if (error) {
    return <div className='history_null'>{error}</div>;
  }

  return (
    <div className='Historypagegrp'>
      
      {/* Notification Popup */}
      {showNotificationCopy && (
        <div className="notificationCopy">
          Text copied to clipboard!
        </div>
      )}

      {/* Delete Confirmation Popup */}
      {showDeletePopup && (
        <div className="delete-popup">
          <div className="delete-popup-content">
            <p>Are you sure you want to delete this video? This action cannot be undone.</p>
            <div className="popup-buttons">
              <button onClick={handleDeleteCancel}>Cancel</button>
              <button onClick={handleDeleteConfirmation} disabled={isDeleting}>
                {isDeleting ? "Deleting..." : "Delete"}
              </button>
            </div>
            {deleteResult && (
              <div className={`delete-result ${deleteResult}`}>
                {deleteResult === 'success' ? 'Video deleted successfully!' : 'Error deleting video.'}
              </div>
            )}
          </div>
        </div>
      )}

      {historyData.slice().reverse().map((item, index) => {
        const words = item.ai_result.split(' ');
        const wordLimit = 8; // Show only the first 8 words
        
        let firstWords = words.slice(0, wordLimit).join(' ');
        
        if (words.length > wordLimit) {
          firstWords = firstWords.trim() + '...';
        }

        return (
          
          <div className="repeating-group" key={index} onClick={() => handleDocumentClick(item)}>
            <div className="video-thumbnail">
            <video preload='metadata' src={item.topic_type} width="55%" style={{borderRadius: '20px',padding:"5px"}} ></video>
            </div>

            <div className="text-container">
              <p>{firstWords}</p>

              <div className="icon-container">
                <button className='delete_btn_history' onClick={(e) => { e.stopPropagation(); handleDeleteClick(item.topic_type); }}>
                  <img src={copy} alt="Delete" />
                  Delete
                </button>
                <button className='delete_btn_history'>
                  <img
                    src={share}
                    alt="Share"
                    onClick={(e) => { e.stopPropagation(); handleShare(item.topic_type); }}
                  />
                </button>
              </div>
            </div>
          </div>
          
        );
      })}
    </div>
  );
};

export default History;
